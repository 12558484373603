.inputBox {
    background-color: transparent;
    height: 100%;
    border: none;
    width: 100%;
    font-size: 1.3rem;
    margin-left: 5px;
}

.inputBox:focus {
    outline: none;
}

.result-list {
    width: 100%;
    background-color: rgb(253, 231, 202);
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 8px rgb(255, 200, 128);
    border-radius: 10px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: scroll;
}

.search-result {
    padding: 10px 20px;
}

.search-result:hover {
    background-color: #efefef;
    cursor: pointer;
}


.dropzone {
    border: 2px dashed #007bff;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.error {
    color: red;
    margin-top: 10px;
    font-size: 12px !important;
}

.success {
    color: green;
    margin-top: 10px;
}

button {
    margin-top: 10px;
}

progress {
    display: block;
    margin-top: 10px;
}
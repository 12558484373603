.container-fluid {
    margin-top: 20px;
}

.region {
    position: absolute;
    box-sizing: border-box;
    background-color: #ffff;
}

.laybtn {
    cursor: pointer;
    margin: 5px;
    padding: 5px;
}

.layoutbtnline {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    align-items: center;
}

.res {
    margin-left: 10px;
    font-size: 14px;
    color: black;
}

.media {
    /* position: absolute; */
    bottom: 10px;
    left: 10px;
    margin-left: 10px;
    width: 95%;
    color: black;
    font-size: 10px;
}

.medianame{
    font-size: 13px;
    line-height: 13px;
}

.top-ads {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
}
.resize-handle {
    width: 10px;
    height: 10px;
    background: #333;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.resize-handle {
    cursor: se-resize;
}



.layoutbtnline {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

#layout {
    background-color: #000000; /* Ensure background color is applied */
}

.layout {
    border: 1px solid #ccc;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .region {
    border: 1px solid #eee;
    position: absolute;
    overflow: hidden;
  }
  
  .media-player {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

